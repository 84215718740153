import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
        <header>
            <h1>RONALDO MAGRARE Test</h1>
            <p>Developer/Support</p>
            <p>Email: rmagrare7@gmail.com</p>
            <p>Phone: (647) 803-7490</p>
          </header>
          <section class="summary">
            <h2>Professional Summary</h2>
            <ul>
              <li>✓ Highly motivated Full Stack Developer with 6 years of experience in web/mobile development and 2 years in technical support.</li>
              <li>✓ Proficient in Javascript, CSS, React, React Native, and SQL.</li>
              <li>✓ Strong knowledge of computer networks, TCP/IP protocols, and network security.</li>
              <li>✓ Skilled in configuring routers, switches, and firewalls.</li>
              <li>✓ Excellent problem-solving and analytical abilities.</li>
            </ul>
          </section>
          <section class="skills">
            <h2>Technical Skills</h2>
            <ul>
              <li>Front-End Technologies: React, React-Native, Redux, CSS3/SASS, JavaScript ES6, Responsive Design, Websocket</li>
              <li>Back-End Technologies: Python, NodeJS, AWS, REST API, PHP</li>
              <li>Databases: Postgres, MySQL, MongoDb</li>
              <li>Process Automation and Version Control Tools: Git, Npm, Webpack, Jest</li>
              <li>IDE Tools: Visual Studio Code, Xcode</li>
              <li>Operating Systems: Linux, Mac, Windows</li>
              <li>Networking: Router, L2/L3 Switches, HSRP, ACL, DNS, DHCP, IPv4/IPv6</li>
            </ul>
          </section>
          <section class="education">
            <h2>Education</h2>
            <p><strong>Diploma in Computer System Networking Technician (Fast-Track)</strong></p>
            <p>Centennial College | September 2022 to August 2023</p>
            <p><strong>Diploma in Software Engineering Technician</strong></p>
            <p>Centennial College - Toronto, ON | September 2011 to May 2013</p>
          </section>
 
    </div>
  );
}

export default App;
